<template>
  <div
    class="header_container"
    :style="{
      background:
        !fixHeaderTag && !isPhoneWidth ? `rgba(255,255,255,${opacity})` : '',
    }"
    :class="{ fix_header: fixedEndTag || fixHeaderTag || isPhoneWidth }"
  >
    <div class="content">
      <div class="logo_container" @click="logo_container_clicked">
        <img
          class="logo"
          :src="`${$store.state.assetsUrl}/imgs/logo1214.webp`"
        />
        <div class="logotitle">Litely App</div>
      </div>
      <div class="navs" v-if="!isPhoneWidth">
        <div class="blog" @click="goLogin">Log In</div>
        <div class="blog" @click="blognav_clicked">Fasting blog</div>
        <div class="press" @click="pressnav_clicked">Press</div>
        <div class="contact" @click="contactnav_clicked">Contact</div>
      </div>
      <div class="nav_box" v-else>
        <div class="nav_box_icos">
          <img
            v-show="!showNavTag"
            class="hiddenExe"
            @click="showNavTag = true"
            src="@/assets/img/more.svg"
          />
        </div>
        <div class="nav_box_list" v-show="showNavTag">
          <div class="topbar hiddenExe">
            <img
              class="hiddenExe"
              @click="showNavTag = false"
              src="@/assets/img/close.svg"
            />
          </div>
          <div class="nav_box_list_inner hiddenExe">
            <div
              class="nav_box_list_inner_item hiddenExe"
              @click="blognav_clicked"
            >
              Fasting blog
            </div>
            <div
              class="nav_box_list_inner_item hiddenExe"
              @click="pressnav_clicked"
            >
              Press
            </div>
            <div
              class="nav_box_list_inner_item hiddenExe"
              @click="contactnav_clicked"
            >
              Contact
            </div>
            <div class="nav_box_list_inner_item hiddenExe" @click="goLogin">
              Log In
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isPhoneWidth: false,
      fixHeaderTag: false,
      fixedEndTag: false,
      showNavTag: false,
      opacity: 0,
    };
  },
  created() {
    if (this.$route.path !== "/") {
      this.fixHeaderTag = true;
    }
  },
  mounted() {
    document.addEventListener("click", this.checkClick, { passive: true });
    this.resetWidth();
    window.addEventListener("resize", this.resetWidth, { passive: true });
    document.onscroll = () => {
      this.showNavTag = false;
      this.opacity = (document.documentElement.scrollTop / 779).toFixed(1);
      if (this.opacity > 0.8) {
        this.fixedEndTag = true;
      } else {
        this.fixedEndTag = false;
      }
    };
  },
  watch: {
    $route: {
      deep: true,
      handler: function () {
        this.showNavTag = false;
        if (this.$route.path !== "/") {
          this.fixHeaderTag = true;
        } else {
          this.fixHeaderTag = false;
        }
      },
    },
  },
  components: {},
  computed: {},
  methods: {
    checkClick(e) {
      if (e.target.className.indexOf("hiddenExe") == -1) {
        this.showNavTag = false;
      }
    },
    goLogin() {
      this.$router.push("/login");
    },
    resetWidth() {
      var width = document.body.clientWidth;
      if (width <= 500) {
        this.isPhoneWidth = true;
      } else {
        this.isPhoneWidth = false;
      }
    },
    logo_container_clicked() {
      this.$router.push("/");
    },
    blognav_clicked() {
      window.location.href = "https://blog.litely.life/";
    },
    pressnav_clicked() {
      this.$router.push("/press");
    },
    contactnav_clicked() {
      this.$router.push("/contact");
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.resetWidth);
    document.removeEventListener("click", this.checkClick);
  },
};
</script>
<style lang="less" scoped>
@import "@/css/less_custom.less";

.fix_header {
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.header_container {
  // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  height: 84px;
  width: 84%;
  padding-left: 8%;
  padding-right: 8%;
  // background-color: rosybrown;
  // background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  .content {
    width: 100%;
    height: 100%;
    // background-color: cyan;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo_container {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .logo {
        width: 40px;
        height: 40px;
        margin-right: 20px;
      }

      .logotitle {
        font-size: 30px;
        color: rgba(253, 124, 97, 1);
      }

      @media screen and (max-width: @MobileMaxWidth) {
        .logo {
          width: 38px;
          height: 38px;
          margin-right: 10px;
        }

        .logotitle {
          font-size: 23px;
        }
      }
    }

    .navs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 280px;

      // background-color: cyan;
      div {
        font-size: 14px;
        color: rgba(51, 51, 51, 1);
        font-weight: 700;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: @MobileMaxWidth) {
  .header_container {
    padding-left: 5%;
    padding-right: 5%;
    width: 90%;
    height: 44px;

    .content {
      .logo_container {
        .logo {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }

        .logotitle {
          font-size: 12px;
          font-family: Helvetica;
        }
      }
    }
  }

  .nav_box {
    display: flex;
    justify-content: center;
    align-items: center;

    .nav_box_icos {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 22px;
        height: 22px;
      }
    }

    .nav_box_list {
      position: fixed;
      width: 100%;
      left: 0;
      top: 0;

      background: linear-gradient(45deg, #ff646b 0%, #ffa164 100%);

      .topbar {
        height: 44px;
        display: flex;
        justify-content: flex-end;

        img {
          width: 22px;
          height: 22px;
          margin: 11px 16px 0 0;
        }
      }

      .nav_box_list_inner {
        .nav_box_list_inner_item {
          height: 44px;
          font-size: 16px;
          color: #fff;
          text-align: left;
          margin-left: 5%;
        }
      }
    }
  }
}
</style>
