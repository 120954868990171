import Vue from "vue";
import VueRouter from "vue-router";
import ChangeCookie from '@/assets/utils/change_cookie.js'

//1 注入插件
Vue.use(VueRouter);

// 解决重复点击路由报错的BUG
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, resolve, reject) {
    if (resolve && reject) {
        return originalPush.call(this, location, resolve, reject);
    } else {
        return originalPush.call(this, location, () => { }, () => { });
    }
};

let needTokenNames = [
    'my-subscriptions',
    'my-account',
];

let router = new VueRouter({
    mode: "history",
    routes: [{
        path: "/",
        name: "home",
        component: () => import("@/pages/home.vue"),
    },
    // 教练对话页面（注册页面）
    {
        path: "/consultation",
        name: "consultation",
        component: () => import("@/pages/sign.vue"),
    },
    // 登录页面
    {
        path: "/login",
        name: "login",
        component: () => import("@/pages/login.vue"),
    },
    // 订阅列表页面
    {
        path: "/my-subscriptions",
        name: "my-subscriptions",
        component: () => import("@/pages/subscriptions.vue"),
    },
    // 用户中心页面
    {
        path: "/my-account",
        name: "my-account",
        component: () => import("@/pages/user-center.vue"),
    },
    // 计划列表页面
    {
        path: "/plan",
        name: "plan",
        component: () => import("@/pages/plan-a.vue"),
    },
    // 计划列表页面
    {
        path: "/plan-1",
        name: "plan-1",
        component: () => import("@/pages/plan-b.vue"),
    },
    // 30天退款说明页面
    {
        path: "/refund",
        name: "refund",
        component: () => import("@/pages/refund.vue"),
    },
    // 付款成功、修改密码页面
    {
        path: "/subscribe-done",
        name: "subscribe-done",
        component: () => import("@/pages/payment-successful.vue"),
    },
    // 注册成功后的引导页面
    {
        path: "/how-to-use",
        name: "how-to-use",
        component: () => import("@/pages/guide.vue"),
    },
    {
        path: "/blogs",
        name: "blogs",
        component: () => import("@/pages/blogs.vue"),
    }, {
        path: "/press",
        name: "press",
        component: () => import("@/pages/press.vue"),
    },
    {
        path: "/about",
        name: "about",
        component: () => import("@/pages/about.vue"),
    },
    {
        path: "/contact",
        name: "contact",
        component: () => import("@/pages/contact.vue"),
    },
    {
        path: "/privacy/:type",
        name: "privacy-type",
        component: () => import("@/pages/privacy.vue"),
    },
    {
        path: "/sources-of-recommendations/:type",
        name: "privacy-type",
        component: () => import("@/pages/sources-of-recommendations.vue"),
    },
    {
        path: "/terms/:type",
        name: "terms-type",
        component: () => import("@/pages/terms.vue"),
    },
    {
        path: "/disclaimer/:type",
        name: "disclaimer-type",
        component: () => import("@/pages/disclaimer.vue"),
    },
    {
        path: "/ai-coach-disclaimer/:type",
        name: "ai-coach-disclaimer",
        component: () => import("@/pages/ai-coach-disclaimer.vue"),
    },
    {
        path: "/privacy",
        name: "privacy",
        component: () => import("@/pages/privacy.vue"),
    },
    {
        path: "/terms",
        name: "terms",
        component: () => import("@/pages/terms.vue"),
    },
    {
        path: "/disclaimer",
        name: "disclaimer",
        component: () => import("@/pages/disclaimer.vue"),
    },
    {
        path: "/app",
        name: "app",
        component: () => import("@/pages/middle-page.vue"),
    },
    {
        path: "/share/:code",
        name: "share",
        component: () => import("@/pages/adjust.vue"),
    },
    {
        path: "*",
        redirect: '/'
    }
    ],
});

router.beforeEach((to, from, next) => {
    document.scrollingElement.scrollTop = 0; //让页面滚动到最顶部
    if (needTokenNames.indexOf(to.name) != -1) {
        const changeCookie = new ChangeCookie();
        let token = changeCookie.getCookie('loginToken');
        if (token != null && token != '') {
            next();
        } else {
            next({ path: '/login' })
        }
    } else {
        next();
    }
});

export default router;